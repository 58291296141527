<template>
    <div class="about" id="about">
        <h1>About</h1>
        <h2>Purpose and Concept</h2>
        <p>This tool can help you generate random memorable passwords! The concept is based on the <a class="text-link" href="https://xkcd.com/936/">web comic concerning secure passwords by XKCD.</a></p>
        <h2>Security</h2>
        <p>The app is heavily inspired by <a class="text-link" href="https://xkpasswd.net/s/">xkpasswd.net</a>. I have however decided to port the entire project to the client side, which means the passwords generated by this tool never even touch the web-server.</p>
    </div>
</template>

<script>
    export default {
        name: 'About'
    }
</script>

<style lang="scss">
    @import "../assets/scss/main.scss";
</style>