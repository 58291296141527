<template>
    <div class="footer">
        <span>&copy; 2021 by <a href="https://mikee.dev/" target="_blank" class="text-link">mikee.dev</a></span>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style lang="scss">
    @import '../assets/scss/main.scss';

</style>